import { environment as base } from '@wingstop/environments/environment-base';

export const environment = {
  ...base,
  envName: 'uat-hotfix',

  ecomm: {
    baseUrl: 'https://ecomm.hotfix.us.regional.uat.cf.10piececombo.com',
    menuUrl: 'https://ecomm.hotfix.us.regional.uat.cf.10piececombo.com',
    webSocketUrl: 'wss://websocket.hotfix.us.regional.uat.cf.10piececombo.com'
  },
  ngfeBaseUrl: 'https://hotfix.uat.ordering.wingstop.com',
};
